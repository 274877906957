.btn-kronobit{
  background: #08B0FF;
  color: #FFFFFF;
}

.btn-kronobit.month{
  border-color: #08B0FF;
  background: #ffff;
  color: #08B0FF;
}

.btn-kronobit.month.active{
  background: #08B0FF;
  color: #fff;
}
.dropdown-toggle::after{
  color: #232330
}