@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); 
@import url('https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,600,600i,700,700i&amp;subset=cyrillic,cyrillic-ext,latin-ext');


body{
 /* background-repeat: no-repeat;
  background-size: 100% auto;
  */
  color: #232330;
  font-family: 'Poppins';
  /* background-image: url(img/bg-left.png), url(img/bg-right.png);
  background-position: left center, right top;
  background-repeat: no-repeat, no-repeat; */
  background-color: #F1F1F9;
}
.normal-text{
  color: #232330;
}
.title-text{
  color:#232330;
}
.fw-600{
  font-weight: 500;
}

.head-bar{
  width: 96px;
  height: 8px;
  background-color: #08B0FF;
}
.img-coin{
height: auto;
}
.staking-panel-button-margin{
  margin-top: 80px;
}

.staking-panel-input-margin{
  margin-top: 30px;
}
.footer-margin{
  margin-top: 120px;
  background: #fff;
}
@media(max-width: 480px){
  .footer-margin{
    margin-top: 60px;
  }
  .container.container-mobile{
    padding-bottom: 0 !important;
  }
}

/* .btn-primary{
  border-radius: 18px;
} */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.text-success{
  color: #222222 !important;
}

.menu-ul-footer a{
  float: left;
  width:100%;
}
.menu-ul-footer a{
  font-size: 13px;
  margin-bottom: 25px;
  text-decoration: none;
}
.menu-ul-footer a:first-child{
  font-size: 16px;
}
footer a{
  text-decoration: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

/* ===== heroes =====*/
@media (min-width: 992px) {
  .rounded-lg-3 { border-radius: .3rem; }
}
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

/* ===== HEADER =====*/
.form-control-dark {
  border-color: var(--bs-gray);
}
.form-control-dark:focus {
  border-color: #fff;
  box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .25);
}

.text-small {
  font-size: 85%;
}

.dropdown-toggle {
  outline: 0;
}
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

/* ===== FOOTER =====*/
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}


.icon-lg{
  height: 5rem;
  width: 5rem;
}

.btn-danger1{
  background: #08B0FF;
    color: #fff;
}
.btn-danger1:hover{
  background: #08B0FF;
  color: #fff;
}
.CEX2{
  margin-right: 70px;
}
@media (max-width: 991px) {
  .col-3.col-3-connet{
    width: 100%;
  }
  .mgl-auto{
    margin-left: auto;
  }
  .CEX2{
    margin-right: 0px;
  }
}
@media (max-width: 480px) {
  header a img.logon{
    width: 90px;
  }
  header .container{
    padding:0;
  }
  .flex-wrap-footer a{
    width:47%;
  }
}